.footer {
    background: #ccc;
    color: #000;
    width: 100%;
}
.footer :last-child {
    margin-bottom: 0;
}
.footer .fat-footer {
    background: #061213;
    width: 100%;
}
.footer .fat-footer .footer-content {
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 40px;
    padding-top: 40px;
}
.footer .fat-footer .footer-content .ft-logo {
    text-align: center;
    width: 100%;
}
.footer .fat-footer .footer-content .ft-contacts,
.footer .fat-footer .footer-content .ft-cols {
    width: 100%;
}
.footer .fat-footer .footer-content .ft-contacts p {
    line-height: 30px;
}
.footer .fat-footer .footer-content .ft-contacts a,
.footer .fat-footer .footer-content .ft-contacts a:hover,
.footer .fat-footer .footer-content .ft-contacts a:active {
    color: #fff;
}
.footer .fat-footer .footer-content .ft-contacts svg {
    fill: #fff;
}
.footer .fat-footer .footer-content .ft-col {
    display: inline-block;
}
.footer .fat-footer .footer-content ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.footer .fat-footer .footer-content ul li {
    margin: 0;
}
.footer .fat-footer .footer-content ul li a {
    color: #ccc;
    display: inline-block;
    margin: 0 0 8px;
    text-decoration: none;
}
.footer .fat-footer .footer-content .follow-us {
    display: inline-flex;
    justify-content: center;
    width: 100%;
}
.footer .fat-footer .follow-us h6 {
    margin: 0 10px 0 0;
}
.footer .fat-footer .follow-us .social-links {
    line-height: 0;
}
.footer .fat-footer .follow-us a {
    display: inline-block;
    line-height: 0;
    margin-right: 10px;
}
.footer .fat-footer .follow-us a svg path {
    fill: #727272;
}
.footer .fat-footer .follow-us a:hover svg path {
    fill: #0175a2;
}
.footer .fat-footer .follow-us a:last-child {
    margin-right: 0;
}
.footer .fat-footer .follow-us a .fa {
    font-size: 25px;
}
.footer .legal-footer {
    width: 100%;
}
.footer .legal-footer .legal-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 30px;
    padding-top: 30px;
    text-align: center;
}
.footer .legal-footer .logo img {
    opacity: 0.5;
    width: 60px;
}

@media only screen and (min-width: 37.5em) {
    .footer .fat-footer .footer-content {
        flex-direction: row;
        padding-bottom: 80px;
        padding-top: 80px;
    }
    .footer .fat-footer .footer-content .ft-logo {
        width: 300px;
    }
    .footer .fat-footer .footer-content .ft-contacts,
    .footer .fat-footer .footer-content .ft-cols {
        width: calc(50% - 250px);
    }
    .footer .fat-footer .footer-content .follow-us {
        justify-content: end;
        width: 200px;
    }
}