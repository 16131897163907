@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300&display=swap);
html, body, header, footer, h1, h2, h3, h4, h5, h6, ul, ol, li, p, a, span {
    margin: 0;
    padding: 0;
}
body {
    color: #4d4c4c;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}
body * {
    font: inherit;
    letter-spacing: 1px;
}
body, body * {
    box-sizing: border-box;
}
.pri-0 {
    font-size: 32px;
    line-height: 1.1875em;
    margin-bottom: 1.1875em;
    font-weight: 300;
}
h1,
.pri-1 {
    font-size: 28px;
    line-height: 1.214em;
    margin-bottom: 1.214em;
    font-weight: 300;
}
h2,
.pri-2 {
    font-size: 24px;
    line-height: 1.167em;
    margin-bottom: 1.167em;
    font-weight: 300;
}
h3,
h4,
.pri-3,
.pri-4 {
    font-size: 20px;
    line-height: 1.2em;
    margin-bottom: 1.2em;
    font-weight: 300;
}
h3 {
    font-weight: 300;
}
h5,
.pri-5,
p,
h6,
.pri-6,
li {
    font-size: 16px;
    line-height: 1.375em;
    margin-bottom: 1.375em;
    font-weight: 300;
}
li,
li a {
    line-height: 1.25em;
    margin-bottom: 1.25em;
}
li a {
    font-weight: 300;
}
p.util {
    font-size: 14px;
    line-height: 1.286em;
    margin-bottom: 1.286em;
    font-weight: 300;
}
p strong,
p.util strong {
    font-weight: 300;
}
a {
    color: #007493;
    text-decoration: none;
}
a:hover {
    color: #005073;
    text-decoration: none;
}
a:active {
    color: #6f53bc;
}
.touch a:hover,
.touch a:active {
    text-decoration: none;
}
ul,
ol {
    margin-bottom: 1.429em;
}
ul                 { list-style-type: disc; }
ul.no-bullets      { list-style-type: none; }
ol                 { list-style-type: decimal; }
ol.u-alpha {
    list-style-type: upper-alpha;
}
ol.l-alpha {
    list-style-type: lower-alpha;
}
ol.u-roman {
    list-style-type: upper-roman;
}
ol.l-roman {
    list-style-type: lower-roman;
}
ul li              { margin-left: 17px; }
ul.no-bullets li   { margin-left: 0; }
ol li              { margin-left: 25px; }
ul.bullets {
    list-style-type: none;
}
ul.bullets li {
    position: relative;
}
ul.bullets li::before {
    border-bottom: 4px solid transparent;
    border-left: 6px solid;
    border-top: 4px solid transparent;
    content: '';
    display: block;
    height: 0;
    left: -15px;
    top: 4px;
    position: absolute;
    width: 0;
}
.txt-blue {
    color: #00bceb;
}
.txt-red {
    color: #db0b5b;
}
.txt-light {
    color: #f6f1ed;
}
.txt-light-grey {
    color: #898888;
}
.txt-grey {
    color: #737e92;
}
.txt-dark-grey {
    color: #565656;
}
.txt-dark-head {
    color: #564f6f;
}
.cap {
    letter-spacing: 2px;
    text-transform: uppercase;
}
.ls-4 {
    letter-spacing: 4px;
}
.btn {
    background: transparent;
    border-radius: 100px;
    cursor: pointer;
    display: block;
    font-size: 13px;
    line-height: 1.25em;
    margin: 0;
    min-width: 140px;
    padding: 5px 20px 6px;
    text-align: center;
}
.btn:hover {
    text-decoration: none;
}
.btn.pri0 {
    border: 2px solid #db0b5b;
    color: #f6f1ed;
}
.btn.pri0.solid::before {
    border-radius: 100px;
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    background: #db0b5b;
    z-index: -1;
}
.btn.pri0.solid {
    color: #000;
    position: relative;
    font-weight: 300;
}
.btn.pri0.solid :hover {
    background-color: #33c9ef;
    border: 2px solid #33c9ef;
    color: #f6f1ed;
}
.btn.pri0.solid :active {
    background-color: #66d6f2;
    border: 2px solid #66d6f2;
    color: #f6f1ed;
}
.btn.pri0.solid :focus {
    box-shadow: 0 0 8px 0 #00bceb;
}
.btn.icon svg {
    margin: 0 10px -6px 0;
    width: 15px;
}
.btn.pri0.icon svg {
    fill: #f6f1ed;
}
.btn.pri1 {
    border: 2px solid #00bceb;
    color: #00bceb;
}
.btn.pri1.solid {
    background-color: #00bceb;
    color: #000;
    font-weight: 300;
}
.btn.pri1.solid :hover {
    background-color: #0175a2;
    border: 2px solid #0175a2;
    color: #f6f1ed;
}
.btn.pri1.solid :active {
    background-color: #0175a2;
    border: 2px solid #0175a2;
    color: #f6f1ed;
}
.btn.pri1.solid :focus {
    box-shadow: 0 0 8px 0 #0175a2;
}
.btn.pri1.icon svg {
    fill: #00bceb;
}
.btn.pri1.solid.icon svg {
    fill: #000;
}
.btn.light {
    border: 2px solid #f6f1ed;
    color: #00bceb;
}
.btn.light:hover {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    color: #00bceb;
}
.btn.light:active {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    color: #00bceb;
}
.btn.light:focus {
    box-shadow: 0 0 8px 0 #f6f1ed;
}
.btn.light.icon svg {
    fill: #00bceb;
}
.text-link {
    color: #00bceb;
    cursor: pointer;
    display: inline-block;
    font-weight: 300;
}
.btns li {
    display: block;
    margin: 0 20px 20px 0;
}
:last-child {
    margin-bottom: 0;
}

/*  Small   = 600 to 767 pixels */
@media only screen and (min-width: 37.5em) {
    .pri-0 {
        font-size: 46px;
        line-height: 1.217em;
        margin-bottom: 1.217em;
    }
    h1,
    .pri-1 {
        font-size: 36px;
        line-height: 1.222em;
        margin-bottom: 1.222em;
    }
    h2,
    .pri-2 {
        font-size: 28px;
        line-height: 1.214em;
        margin-bottom: 1.214em;
        font-weight: 300;
    }
    h3,
    h3 i,
    h3 em,
    .pri-3,
    .pri-3 i,
    .pri-3 em {
        font-size: 22px;
        line-height: 1.273em;
        margin-bottom: 0.75em;
    }
    h4,
    .pri-4 {
        font-size: 20px;
        line-height: 1.2em;
        margin-bottom: 1.2em;
    }
    p,
    h6,
    .pri-6 {
        line-height: 1.5em;
    }
    .btn {
        display: inline-block;
    }
    .btns li {
        display: inline-block;
    }
    .btns li:last-child {
        margin: 0 0 20px 0;
    }
}

/* Medium  = 768 to 959 pixels */
@media only screen and (min-width: 48em) {
    .pri-0 {
        font-size: 64px;
        line-height: 1.1875em;
        margin-bottom: 1.1875em;
    }
    h1,
    .pri-1 {
        font-size: 46px;
        line-height: 1.217em;
        margin-bottom: 1.217em;
    }
    h2,
    .pri-2 {
        font-size: 32px;
        line-height: 1.4375em;
        margin-bottom: 0.875em;
    }
    h3,
    h3 i,
    h3 em,
    .pri-3,
    .pri-3 i,
    .pri-3 em {
        font-size: 28px;
        line-height: 1.286em;
        margin-bottom: 0.75em;
    }
    h4,
    .pri-4 {
        font-size: 22px;
        line-height: 1.27em;
        margin-bottom: 0.5em;
    }
    h5,
    .pri-5 {
        font-size: 18px;
        line-height: 1.27em;
        margin-bottom: 0.56em;
    }
    p,
    p a,
    p i,
    p em,
    h6,
    .pri-6,
    li,
    li a {
        font-size: 14px;
        line-height: 1.285em;
        margin-bottom: 0.714em;
    }
    p.util,
    .util * {
        font-size: 12px;
        line-height: 1.5em;
    }
}

/* LARGE = 1024px + */
@media only screen and (min-width:64em) { 
}
.header {
    display: flex;
    height: 60px;
    left: 0;
    position: fixed;
    top: 0;
    transition: top 0.3s ease-in-out;
    width: 100%;
    z-index: 10;
}
.header.sticky {
    background: #ccc;
}
.header .wrapper {
    display: flex;
    position: relative;
}
.header .logo {
    align-items: center;
    display: inline-flex;
    justify-content: left;
    width: 100%;
}
.header .logo a {
    display: flex;
    position: relative;
}
.header .logo svg:first-child {
    margin-right: 20px;
}
.header .logo span {
    background: #0175a2;
    border-radius: 4px;
    bottom: -15px;
    color: #fff;
    font-size: 9px;
    font-weight: 400;
    padding: 2px 5px;
    position: absolute;
    right: 0;
}
.header .menu .hb-menu {
    height: 60px;
    padding: 17.5px 10px;
    position: absolute;
    right: 10px;
    top: 0;
    width: 50px;
}
.header .menu .hb-menu span,
.header .menu .hb-menu span:before,
.header .menu .hb-menu span:after {
    background: #4d4c4c;
    border-radius: 10px;
    display: block;
    height: 5px;
    width: 30px;
}
.header .menu .hb-menu span {
    position: relative;
    top: 10px;
    transition: transform 0.25s ease-in-out 0.15s;
}
.header .menu.open .hb-menu span {
    transform: rotate(-45deg);
}
.header .menu .hb-menu span:before {
    content: '';
    position: absolute;
    top: -10px;
    transition: top 0.15s ease-in-out, transform 0.25s ease-in-out 0.15s;
}
.header .menu .hb-menu span:after {
    content: '';
    position: absolute;
    top: 10px;
    transition: top 0.15s ease-in-out, transform 0.25s ease-in-out 0.15s;
}
.header .menu.open .hb-menu span:before,
.header .menu.open .hb-menu span:after {
    top: 0;
    transform: rotate(90deg);
}
.header .menu ul {
    background: #ccc;
    border-radius: 0 0 10px 10px;
    box-shadow: 1px 1px 1px 1px #ccc;
    display: none;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 60px;
    width: 100%;
}
.header .menu.open ul {
    display: block;
}
.header + .header-spacer {
    height: 60px;
    width: 100%;
}

@media only screen and (min-width: 37.5em) {
    .header {
        height: 80px;
    }
    .header .wrapper {
        justify-content: flex-end;
    }
    .header .logo {
        justify-content: center;
        width: calc(100% / 3);
    }
    .header .menu {
        align-items: center;
        display: inline-flex;
        justify-content: right;
        width: calc(100% / 3);
    }
    .header .menu .hb-menu {
        display: none;
    }
    .header .menu ul {
        background: transparent;
        border-radius: 0;
        box-shadow: none;
        display: flex;
        justify-content: flex-end;
        padding: 0;
        position: static;
    }
    .header .menu li {
        display: inline-block;
        margin: 0;
        padding: 0 20px;
    }
    .header + .header-spacer {
        height: 80px;
    }
}
.footer {
    background: #ccc;
    color: #000;
    width: 100%;
}
.footer :last-child {
    margin-bottom: 0;
}
.footer .fat-footer {
    background: #061213;
    width: 100%;
}
.footer .fat-footer .footer-content {
    color: #fff;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    padding-bottom: 40px;
    padding-top: 40px;
}
.footer .fat-footer .footer-content .ft-logo {
    text-align: center;
    width: 100%;
}
.footer .fat-footer .footer-content .ft-contacts,
.footer .fat-footer .footer-content .ft-cols {
    width: 100%;
}
.footer .fat-footer .footer-content .ft-contacts p {
    line-height: 30px;
}
.footer .fat-footer .footer-content .ft-contacts a,
.footer .fat-footer .footer-content .ft-contacts a:hover,
.footer .fat-footer .footer-content .ft-contacts a:active {
    color: #fff;
}
.footer .fat-footer .footer-content .ft-contacts svg {
    fill: #fff;
}
.footer .fat-footer .footer-content .ft-col {
    display: inline-block;
}
.footer .fat-footer .footer-content ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.footer .fat-footer .footer-content ul li {
    margin: 0;
}
.footer .fat-footer .footer-content ul li a {
    color: #ccc;
    display: inline-block;
    margin: 0 0 8px;
    text-decoration: none;
}
.footer .fat-footer .footer-content .follow-us {
    display: inline-flex;
    justify-content: center;
    width: 100%;
}
.footer .fat-footer .follow-us h6 {
    margin: 0 10px 0 0;
}
.footer .fat-footer .follow-us .social-links {
    line-height: 0;
}
.footer .fat-footer .follow-us a {
    display: inline-block;
    line-height: 0;
    margin-right: 10px;
}
.footer .fat-footer .follow-us a svg path {
    fill: #727272;
}
.footer .fat-footer .follow-us a:hover svg path {
    fill: #0175a2;
}
.footer .fat-footer .follow-us a:last-child {
    margin-right: 0;
}
.footer .fat-footer .follow-us a .fa {
    font-size: 25px;
}
.footer .legal-footer {
    width: 100%;
}
.footer .legal-footer .legal-content {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    padding-bottom: 30px;
    padding-top: 30px;
    text-align: center;
}
.footer .legal-footer .logo img {
    opacity: 0.5;
    width: 60px;
}

@media only screen and (min-width: 37.5em) {
    .footer .fat-footer .footer-content {
        flex-direction: row;
        padding-bottom: 80px;
        padding-top: 80px;
    }
    .footer .fat-footer .footer-content .ft-logo {
        width: 300px;
    }
    .footer .fat-footer .footer-content .ft-contacts,
    .footer .fat-footer .footer-content .ft-cols {
        width: calc(50% - 250px);
    }
    .footer .fat-footer .footer-content .follow-us {
        justify-content: end;
        width: 200px;
    }
}
.home {
    width: 100%;
}
.home h2.title {
    margin-top: 60px;
}
.home .img-slider {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 80px;
    width: 100%;
}
.home .img-slider .med {
    align-items: center;
    display: flex;
    overflow: hidden;
    padding-top: 70%;
    position: relative;
    width: 100%;
}
.home .img-slider .med .slide {
    -webkit-animation: slideOut 1s ease-in-out;
            animation: slideOut 1s ease-in-out;
    left: -100%;
    padding: 0 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}
.home .img-slider .med .slide.current {
    -webkit-animation: slideIn 1s ease-in-out;
            animation: slideIn 1s ease-in-out;
    left: 0;
}
.home .img-slider .med .slide img {
    width: 100%;
}
.home .img-slider .cnt {
    padding: 20px 0;
    width: 100%;
}
.home .tiles {
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
    gap: 50px;
    margin-bottom: 100px;
    text-align: center;
    width: 100%;
}
.home .tiles .tile {
    width: 100%;
}
.home .tiles .tile .med {
    margin-bottom: 20px;
}
.home .tiles .tile .med svg {
    fill: #6c63ff;
}
.home .spotlight {
    margin-bottom: 80px;
    width: 100%;
}
.home .spotlight .title {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    width: 100%;
}
.home .spotlight .title svg {
    fill: #6c63ff;
    margin: 0 20px 0 0;
}
.home .spotlight .title h3 {
    align-self: center;
}
.home .spotlight .cnt {
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    margin: 0 0 20px;
    width: 100%;
}
.home .spotlight .cnt.river {
    flex-direction: column-reverse;
}
.home .spotlight .cnt:last-child {
    margin: 0;
}
.home .spotlight .med,
.home .spotlight .txt {
    width: 100%;
}
.home .spotlight .med {
    display: flex;
    justify-content: flex-end;
}
.home .spotlight .cnt.river .med {
    justify-content: flex-start;
}
.home .spotlight .med img {
    margin: 0 auto;
    width: 50%;
}
@-webkit-keyframes slideIn {
    0% {
        left: 100%;
    }
    100% {
        left: 0;
    }
}
@keyframes slideIn {
    0% {
        left: 100%;
    }
    100% {
        left: 0;
    }
}
@-webkit-keyframes slideOut {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}
@keyframes slideOut {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}

@media only screen and (min-width: 37.5em) {
    .home h2.title {
        text-align: center;
    }
    .home .img-slider {
        flex-direction: row;
        margin-bottom: 120px;
    }
    .home .img-slider .med {
        padding: 0;
        width: 50%;
    }
    .home .img-slider .med .slide {
        padding: 0 115px;
    }
    .home .img-slider .cnt {
        padding: 130px 50px;
        width: 50%;
    }
    .home .tiles {
        flex-direction: row;
        margin-bottom: 120px;
    }
    .home .spotlight {
        margin-bottom: 120px;
    }
    .home .spotlight .title {
        margin-bottom: 80px;
    }
    .home .spotlight .cnt {
        align-items: center;
        flex-direction: row;
        grid-gap: 80px;
        gap: 80px;
        margin: 0 auto 100px;
        width: 70%;
    }
    .home .spotlight .cnt.river {
        flex-direction: row;
    }
    .home .spotlight.var-2 .cnt {
        width: 50%;
    }
    .home .spotlight .cnt:last-child {
        margin: 0 auto;
    }
    .home .spotlight .med img {
        width: 100%;
    }
}
.main,
.main * {
    box-sizing: border-box;
}
.main {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 639px);
}
.mx-width {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    width: 100%;
}
.wa-chat {
    bottom: 30px;
    line-height: 0;
    position: fixed;
    right: 30px;
}
.wa-chat a {
    background: #60d66a;
    border-radius: 50%;
    display: inline-block;
    line-height: 0;
}
.wa-chat a:hover {
    box-shadow: 0px 0px 4px 3px #666;
}
.wa-chat a svg {
    height: 50px;
    width: 50px;
}
svg.mail,
svg.phone {
    margin: 0 8px -2px 0;
}
.cnt-wrap {
    margin-bottom: 30px;
    margin-top: 30px;
}
.cnt-wrap .text {
    margin-bottom: 50px;
}
.cnt-wrap .text:last-child {
    margin-bottom: 0;
}
.cnt-wrap .text p {
    line-height: 24px;
}

@media only screen and (min-width: 37.5em) {
    .wa-chat {
        bottom: 80px;
        right: 80px;
    }
    .wa-chat a {
        line-height: 0;
    }
    .wa-chat a svg {
        height: 60px;
        width: 60px;
    }
    .cnt-wrap {
        margin-bottom: 80px;
        margin-top: 80px;
    }
    .cnt-wrap .text {
        margin-bottom: 50px;
        padding: 0 50px;
    }
}
