.main,
.main * {
    box-sizing: border-box;
}
.main {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 639px);
}
.mx-width {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    width: 100%;
}
.wa-chat {
    bottom: 30px;
    line-height: 0;
    position: fixed;
    right: 30px;
}
.wa-chat a {
    background: #60d66a;
    border-radius: 50%;
    display: inline-block;
    line-height: 0;
}
.wa-chat a:hover {
    box-shadow: 0px 0px 4px 3px #666;
}
.wa-chat a svg {
    height: 50px;
    width: 50px;
}
svg.mail,
svg.phone {
    margin: 0 8px -2px 0;
}
.cnt-wrap {
    margin-bottom: 30px;
    margin-top: 30px;
}
.cnt-wrap .text {
    margin-bottom: 50px;
}
.cnt-wrap .text:last-child {
    margin-bottom: 0;
}
.cnt-wrap .text p {
    line-height: 24px;
}

@media only screen and (min-width: 37.5em) {
    .wa-chat {
        bottom: 80px;
        right: 80px;
    }
    .wa-chat a {
        line-height: 0;
    }
    .wa-chat a svg {
        height: 60px;
        width: 60px;
    }
    .cnt-wrap {
        margin-bottom: 80px;
        margin-top: 80px;
    }
    .cnt-wrap .text {
        margin-bottom: 50px;
        padding: 0 50px;
    }
}