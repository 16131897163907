.home {
    width: 100%;
}
.home h2.title {
    margin-top: 60px;
}
.home .img-slider {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 80px;
    width: 100%;
}
.home .img-slider .med {
    align-items: center;
    display: flex;
    overflow: hidden;
    padding-top: 70%;
    position: relative;
    width: 100%;
}
.home .img-slider .med .slide {
    animation: slideOut 1s ease-in-out;
    left: -100%;
    padding: 0 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}
.home .img-slider .med .slide.current {
    animation: slideIn 1s ease-in-out;
    left: 0;
}
.home .img-slider .med .slide img {
    width: 100%;
}
.home .img-slider .cnt {
    padding: 20px 0;
    width: 100%;
}
.home .tiles {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-bottom: 100px;
    text-align: center;
    width: 100%;
}
.home .tiles .tile {
    width: 100%;
}
.home .tiles .tile .med {
    margin-bottom: 20px;
}
.home .tiles .tile .med svg {
    fill: #6c63ff;
}
.home .spotlight {
    margin-bottom: 80px;
    width: 100%;
}
.home .spotlight .title {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    width: 100%;
}
.home .spotlight .title svg {
    fill: #6c63ff;
    margin: 0 20px 0 0;
}
.home .spotlight .title h3 {
    align-self: center;
}
.home .spotlight .cnt {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 0 20px;
    width: 100%;
}
.home .spotlight .cnt.river {
    flex-direction: column-reverse;
}
.home .spotlight .cnt:last-child {
    margin: 0;
}
.home .spotlight .med,
.home .spotlight .txt {
    width: 100%;
}
.home .spotlight .med {
    display: flex;
    justify-content: flex-end;
}
.home .spotlight .cnt.river .med {
    justify-content: flex-start;
}
.home .spotlight .med img {
    margin: 0 auto;
    width: 50%;
}
@keyframes slideIn {
    0% {
        left: 100%;
    }
    100% {
        left: 0;
    }
}
@keyframes slideOut {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}

@media only screen and (min-width: 37.5em) {
    .home h2.title {
        text-align: center;
    }
    .home .img-slider {
        flex-direction: row;
        margin-bottom: 120px;
    }
    .home .img-slider .med {
        padding: 0;
        width: 50%;
    }
    .home .img-slider .med .slide {
        padding: 0 115px;
    }
    .home .img-slider .cnt {
        padding: 130px 50px;
        width: 50%;
    }
    .home .tiles {
        flex-direction: row;
        margin-bottom: 120px;
    }
    .home .spotlight {
        margin-bottom: 120px;
    }
    .home .spotlight .title {
        margin-bottom: 80px;
    }
    .home .spotlight .cnt {
        align-items: center;
        flex-direction: row;
        gap: 80px;
        margin: 0 auto 100px;
        width: 70%;
    }
    .home .spotlight .cnt.river {
        flex-direction: row;
    }
    .home .spotlight.var-2 .cnt {
        width: 50%;
    }
    .home .spotlight .cnt:last-child {
        margin: 0 auto;
    }
    .home .spotlight .med img {
        width: 100%;
    }
}