.header {
    display: flex;
    height: 60px;
    left: 0;
    position: fixed;
    top: 0;
    transition: top 0.3s ease-in-out;
    width: 100%;
    z-index: 10;
}
.header.sticky {
    background: #ccc;
}
.header .wrapper {
    display: flex;
    position: relative;
}
.header .logo {
    align-items: center;
    display: inline-flex;
    justify-content: left;
    width: 100%;
}
.header .logo a {
    display: flex;
    position: relative;
}
.header .logo svg:first-child {
    margin-right: 20px;
}
.header .logo span {
    background: #0175a2;
    border-radius: 4px;
    bottom: -15px;
    color: #fff;
    font-size: 9px;
    font-weight: 400;
    padding: 2px 5px;
    position: absolute;
    right: 0;
}
.header .menu .hb-menu {
    height: 60px;
    padding: 17.5px 10px;
    position: absolute;
    right: 10px;
    top: 0;
    width: 50px;
}
.header .menu .hb-menu span,
.header .menu .hb-menu span:before,
.header .menu .hb-menu span:after {
    background: #4d4c4c;
    border-radius: 10px;
    display: block;
    height: 5px;
    width: 30px;
}
.header .menu .hb-menu span {
    position: relative;
    top: 10px;
    transition: transform 0.25s ease-in-out 0.15s;
}
.header .menu.open .hb-menu span {
    transform: rotate(-45deg);
}
.header .menu .hb-menu span:before {
    content: '';
    position: absolute;
    top: -10px;
    transition: top 0.15s ease-in-out, transform 0.25s ease-in-out 0.15s;
}
.header .menu .hb-menu span:after {
    content: '';
    position: absolute;
    top: 10px;
    transition: top 0.15s ease-in-out, transform 0.25s ease-in-out 0.15s;
}
.header .menu.open .hb-menu span:before,
.header .menu.open .hb-menu span:after {
    top: 0;
    transform: rotate(90deg);
}
.header .menu ul {
    background: #ccc;
    border-radius: 0 0 10px 10px;
    box-shadow: 1px 1px 1px 1px #ccc;
    display: none;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 60px;
    width: 100%;
}
.header .menu.open ul {
    display: block;
}
.header + .header-spacer {
    height: 60px;
    width: 100%;
}

@media only screen and (min-width: 37.5em) {
    .header {
        height: 80px;
    }
    .header .wrapper {
        justify-content: flex-end;
    }
    .header .logo {
        justify-content: center;
        width: calc(100% / 3);
    }
    .header .menu {
        align-items: center;
        display: inline-flex;
        justify-content: right;
        width: calc(100% / 3);
    }
    .header .menu .hb-menu {
        display: none;
    }
    .header .menu ul {
        background: transparent;
        border-radius: 0;
        box-shadow: none;
        display: flex;
        justify-content: flex-end;
        padding: 0;
        position: static;
    }
    .header .menu li {
        display: inline-block;
        margin: 0;
        padding: 0 20px;
    }
    .header + .header-spacer {
        height: 80px;
    }
}